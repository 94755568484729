const TableJudgesComponent = () => import('@/views/kpi/TableJudges/TableJudgesView')
const TableJudgesSelComponent = () => import('@/views/kpi/TableJudges/TableJudgesSelView')

export default [
    {
        path:'/TableJudgesIndex',
        name:'TableJudgesComponent',
        component:TableJudgesComponent
    },
    {
        path:'/TableJudgesSel',
        name:'TableJudgesSelComponent',
        component:TableJudgesSelComponent
    }
]