const VacationComponent = () => import('@/views/hrm/Vacation/VacationView')
const VacationCountView = () => import('@/views/hrm/Vacation/VacationCountView')

export default [
    {
        path:'/VacationIndex',
        name:'VacationComponent',
        component:VacationComponent
    },
    {
        path:'/Vacationcount',
        name:'VacationCountView',
        component:VacationCountView
    }
]