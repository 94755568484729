import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//加载elementUI库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Print from '@/assets/js/print.js'
import htmlToPdf from '@/assets/js/htmlToPdf.js'

//加载主样式
import './assets/style/main.css'

//加载自定义图标库
import './assets/icon/iconfont.css'

//注册elementUI库
Vue.use(ElementUI)
//注册打印功能
Vue.use(Print)
//注册导出PDF功能
Vue.use(htmlToPdf)

Vue.config.productionTip = false
// Vue.config.devtools = true

//自定义权限检查指令
Vue.directive('has', {
  bind: (el, binding) => {
    if (!Vue.prototype.$_has(binding.value)) {
      el.style.display = 'none'
    }
  }
})

// 权限检查方法
Vue.prototype.$_has =  (value) => {
  return store.state.authList.indexOf(value) > -1
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
