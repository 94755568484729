import Vue from 'vue'
import VueRouter from 'vue-router'

//路由组加载
import RouteModelRouter from './RouteModelRouter'
import RouteTeamRouter from './RouteTeamRouter'
import RouteRouter from './RouteRouter'
import StaffRouter from './StaffRouter'
import MyInformationRouter from './User/MyInformationRouter'
import MyApplyRouter from './User/MyApplyRouter'
import MyExamineRouter from './User/MyExamineRouter'
import MyCcRouter from './User/MyCcRouter'
import EnterpriseRouter from './EnterpriseRouter'
import DepartmentRouter from './DepartmentRouter'
import PositionRouter from './PositionRouter'
import StoreroomViewRouter from './WareHouse/StoreroomViewRouter'
import LocaltionViewRouter from './WareHouse/LocaltionViewRouter'
import EntryBillRouter from './WareHouse/EntryBillRouter'
import StockRouter from './WareHouse/StockRouter'
import DetryBillRouter from './WareHouse/DetryBillRouter'
import ClientRouter from './business/ClientRouter'
import ClientStockRateRouter from './business/ClientStockRateRouter'
import ProductRouter from './WareHouse/ProductRouter'
import MvtryBillRouter from './WareHouse/MvtryBillRouter'
import ExamineRouter from './ExamineRouter'
import PurchaseRouter from './finance/PurchaseRouter'
import EntertainRouter from './finance/EntertainRouter'
import EntertainClientRouter from './finance/EntertainClientRouter'
import PettyRouter from './finance/PettyRouter'
import BankCardRouter from './finance/BankCardRouter'
import SuppliesCategoryRouter from './office/SuppliesCategoryRouter'
import TravelRouter from './finance/TravelRouter'
import TravelAddressRouter from './finance/TravelAddressRouter'
import ReimbursementRouter from './finance/ReimbursementRouter'
import ReimbursementCategoryRouter from './finance/ReimbursementCategoryRouter'
import MoneyBillRouter from './WareHouse/MoneyBillRouter'
import ReconciliationRouter from './WareHouse/ReconciliationRouter'
import BusinessPaymentRouter from './finance/BusinessPaymentRouter'
import CarPartRouter from './WareHouse/CarPartRouter'
import VacationCategoryRouter from './hrm/VacationCategoryRouter'
import VacationRouter from './hrm/VacationRouter'
import GoOutRouter from './hrm/GoOutRouter'
import OverTimeRouter from './hrm/OverTimeRouter'
import SupplementClockInRouter from './hrm/SupplementClockInRouter'
import ContractRouter from './finance/ContractRouter'
import CooperativeEnterpriseRouter from './business/CooperativeEnterpriseRouter'
import CopRouter from './cop/CopRouter'
import CopTemplateRouter from './cop/CopTemplateRouter'
import CopOrderRouter from './cop/CopOrderRouter'
import CommonTitleRouter from './kpi/CommonTitleRouter'
import CommonContentRouter from './kpi/CommonContentRouter'
import JudgesRouter from './kpi/JudgesRouter'
import TableStaffRouter from './kpi/TableStaffRouter'
import TableJudgesRouter from './kpi/TableJudgesRouter'
import CurrencyCategoryRouter from './finance/CurrencyCategoryRouter'
import CurrencyRouter from './finance/CurrencyRouter'
import BudgetRouter from './finance/BudgetRouter'
import BudgetUseRouter from './finance/BudgetUseRouter'
import TerraceRouter from './WareHouse/TerraceRouter'
import CarBusinessTypeRouter from './WareHouse/CarBusinessTypeRouter'
import CarSizeTypeRouter from './WareHouse/CarSizeTypeRouter'
import CarDriverAppointStaffRouter from './WareHouse/CarDriverAppointStaffRouter'
import CarDriverRouter from './WareHouse/CarDriverRouter'
import AttendanceRouter from './hrm/AttendanceRouter'
import OverTimeDelRouter from './hrm/OverTimeDelRouter'
import StaffEntryRouter from './hrm/StaffEntryRouter'
import CarUseRouter from './hrm/CarUseRouter'
import EntertainUseRouter from './finance/EntertainUseRouter'



//组件加载
const NotFoundView = () => import('@/views/index/NotFoundView')
const IndexView = () => import('@/views/index/IndexView')
const HomeView = () => import('@/views/home/HomeView')
const LoginComponent = () => import('@/views/login/LoginView')

Vue.use(VueRouter)

const routes = [{
  path: '/',
  // name:'index_router',
  component: IndexView,
  children: [{
    path: '', //path为空，会作为默认子路由渲染
    name: 'HomeRouter',
    redirect: '/MyInformation'
    // component: HomeView
  }].concat(
    RouteModelRouter,
    RouteTeamRouter,
    RouteRouter,
    StaffRouter,
    MyInformationRouter,
    MyApplyRouter,
    MyExamineRouter,
    MyCcRouter,
    EnterpriseRouter,
    DepartmentRouter,
    PositionRouter,
    StoreroomViewRouter,
    LocaltionViewRouter,
    ClientRouter,
    ClientStockRateRouter,
    EntryBillRouter,
    StockRouter,
    ProductRouter,
    DetryBillRouter,
    MvtryBillRouter,
    ExamineRouter,
    PurchaseRouter,
    EntertainRouter,
    EntertainClientRouter,
    PettyRouter,
    BankCardRouter,
    SuppliesCategoryRouter,
    TravelRouter,
    TravelAddressRouter,
    ReimbursementRouter,
    ReimbursementCategoryRouter,
    MoneyBillRouter,
    ReconciliationRouter,
    BusinessPaymentRouter,
    CarPartRouter,
    VacationCategoryRouter,
    VacationRouter,
    GoOutRouter,
    OverTimeRouter,
    SupplementClockInRouter,
    ContractRouter,
    CooperativeEnterpriseRouter,
    CopRouter,
    CopTemplateRouter,
    CopOrderRouter,
    CommonTitleRouter,
    CommonContentRouter,
    JudgesRouter,
    TableStaffRouter,
    TableJudgesRouter,
    CurrencyCategoryRouter,
    CurrencyRouter,
    TerraceRouter,
    CarBusinessTypeRouter,
    CarSizeTypeRouter,
    CarDriverAppointStaffRouter,
    CarDriverRouter,
    BudgetRouter,
    BudgetUseRouter,
    AttendanceRouter,
    OverTimeDelRouter,
    StaffEntryRouter,
    EntertainUseRouter,
    CarUseRouter
  )
},{
  path:'/login',
  name:'LoginRouter',
  component:LoginComponent
},{
  path:'/404',
  component:NotFoundView 
},{
  path:'*',
  redirect: '/404'
}]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//解决重复访问同一个路由报错的问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
  //公共页面
  if (to.path === '/login') {
    if (sessionStorage.getItem('x-token')) {
      //跳转到首页
      location.replace('/')
      return
    }
  } else {
    if (!sessionStorage.getItem('x-token')) {
      //跳转到登录页面
      location.replace('/login')
      return
    }
  }
  next()
})

export default router