import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL:  'https://o.yushengtong.cn/',
    authList: [],
    user: {
      my_vacation:{}
    },
    sex: ['男','女'],
    marriage: ['未婚','已婚'],
    education: ['小学','初中','高中','中专','大专','本科','研究生','硕士','博士','其他'],
    isCollapse: false, //侧边导航栏折叠状态
    pageSizes: [10,15,20,25,30,50,100,500],
    state: ['待提交','审批中','已通过','已驳回'],
    examine_type: [
      {
        id: 0,
        name: '采购单申请',
        where: [
          { id: 'money', name: '金额大于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 1,
        name: '招待单申请',
        where: [
          { id: 'money', name: '金额大于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 2,
        name: '备用金申请',
        where: [
          { id: 'money', name: '金额大于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 3,
        name: '入库单申请',
        where: [
          { id: 'client_id_dy', name: '客户等于'},
          { id: 'client_id_bdy', name: '客户不等于'},
          { id: 'labour_money_xy', name: '装卸费小于'},
          { id: 'entry_staff_id_dy', name: '录单员等于'},
          { id: 'entry_staff_id_bdy', name: '录单员不等于'}
        ]
      },{
        id: 4,
        name: '出库单申请',
        where: [
          { id: 'client_id_dy', name: '客户等于'},
          { id: 'client_id_bdy', name: '客户不等于'},
          { id: 'labour_money_xy', name: '装卸费小于'},
          { id: 'entry_staff_id_dy', name: '录单员等于'},
          { id: 'entry_staff_id_bdy', name: '录单员不等于'}
        ]
      },{
        id: 5,
        name: '移库单申请',
        where: [
          { id: 'entry_staff_id_dy', name: '录单员等于'},
          { id: 'entry_staff_id_bdy', name: '录单员不等于'}
        ]
      },{
        id: 6,
        name: '差旅单申请',
        where: [
          { id: 'money', name: '金额大于'},
          { id: 'days', name: '天数大于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 7,
        name: '费用报销单申请',
        where: [
          { id: 'petty_money', name: '实际报销金额大于'},
          { id: 'petty_money_ratio', name: '超抵扣金比例大于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 8,
        name: '休假单申请',
        where: [
          { id: 'duration', name: '休假时长大于'},
          { id: 'mark', name: '假期类别等于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 9,
        name: '仓库账单申请',
        where: [
          { id: 'trim_money', name: '调账金额小于系统金额(数值)'},
          { id: 'trim_money_ratio', name: '调账金额小于系统金额(比例)'}
        ]
      },{
        id: 10,
        name: '业务付款申请',
        where: [
          { id: 'money', name: '付款金额大于'},
          { id: 'domestic_foreign', name: '内外贸等于'}
        ]
      },{
        id: 11,
        name: '外出单申请',
        where: [
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 12,
        name: '加班单申请',
        where: [
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 13,
        name: '补签单申请',
        where: [
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 14,
        name: '合同单申请',
        where: []
      },{
        id: 15,
        name: '合作企业申请',
        where: []
      },{
        id: 16,
        name: '中远电商订单申请',
        where: [
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 17,
        name: '印章使用申请',
        where: [
          { id: 'money', name: '付款金额大于'},
          { id: 'create_staff', name: '创建人等于'}
        ]
      },{
        id: 18,
        name: '入职登记表申请',
        where: [
          { id: 'rank', name: '级别为'},
          { id: 'formal_money', name: '转正薪资大于'}
        ]
      },{
        id: 19,
        name: '公务车使用申请',
        where: [
          { id: 'create_staff', name: '创建人等于'}
        ]
      }
    ],
    office_supplies_type: ['固定资产设备','低值易耗品','招待用品']
  },
  mutations: {
    edit_auth_list(state, data) {
      state.authList = data
    },
    edit_user(state, data) {
      state.user = data
    },
    edit_user_email(state, data) {
      state.user.email = data
    },
    edit_user_phone(state, data) {
      state.user.phone = data
    },
    edit_user_wx(state, data) {
      state.user.wx = data
    },
    edit_is_collapse(state, data) {
      state.isCollapse = data
    },
    edit_user_head_portrait(state, data) {
      state.user.head_portrait = data
    },
    edit_user_wx_id(state, data) {
      state.user.wx_id = data
    },
    edit_user_notice_wx(state, data) {
      state.user.notice_wx = data
    },
    edit_user_notice_email(state, data) {
      state.user.notice_email = data
    }
  },
  actions: {
  },
  modules: {
  }
})
