const StockComponent = () => import('@/views/WareHouse/Stock/StockView')
const EntryAndDetryComponent = () => import('@/views/WareHouse/Stock/EntryAndDetryView')
const StockCountComponent = () => import('@/views/WareHouse/Stock/StockCountView')

export default [
    {
        path:'/StockIndex',
        name:'StockComponent',
        component:StockComponent
    },
    {
        path:'/EntryAndDetry',
        name:'EntryAndDetryComponent',
        component:EntryAndDetryComponent
    },
    {
        path:'/StockCount',
        name:'StockCountComponent',
        component:StockCountComponent
    }
]